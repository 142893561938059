import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import axios from './plugins/axios'
import VCountryRegionSelect from './plugins/vuetify-country-region-input'
import '@fortawesome/fontawesome-free/css/all.css';

import draggable from 'vuedraggable'

Vue.use(VCountryRegionSelect)
Vue.component('draggable', draggable)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (store.state.auth.usertoken !== '' && (to.path === '/login' || to.path === '/register')) {
    next({ path: '/profile' })
  } else if (to.matched.some(record => record.meta.auth === true) && store.state.auth.usertoken === '' && to.path !== '/logout') {
    next({
      path: '/login?from=' + to.path
      // Redirect to original path if specified
    })
  } else {
    next()
  }
})

axios.interceptors.request.use(function (config) {
  return config
}, function (error) {
  return Promise.reject(error)
})

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  console.log(error);

  if (error.response.status === 401) {
    if (router.app._route.path !== '/login') {
      store.dispatch('auth/logout', {}, { root: true })
      router.push({ path: '/login' })
    }
  }

  if (error.response.status === 403) {
    router.push({ path: '/404' })
  }

  if (error.response.status === 404) {
    router.push({ path: '/404' })
  }
  return Promise.reject(error)
})

new Vue({
  vuetify,
  router,
  axios,
  store,
  render: h => h(App)
}).$mount('#app')
